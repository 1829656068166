@font-face {
  font-family: "Semplitica-Pro";
  src: url("./../public/fonts/SemplicitaPro.otf") format("opentype");
}

@font-face {
  font-family: "Bell-MT";
  src: url("./../public/fonts/Bell-MT.otf") format("opentype");
}

* {
  font-family: "Semplitica-Pro", sans-serif;
}

@media print {
  /* Without this, bg colors do not display properly when printing */
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
